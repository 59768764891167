import React from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import { Gallery } from '../components/gallery'
import { logo } from '../assets/images/logo'
import { FONTS } from '../util/fonts'
import me from '../assets/images/me.jpg'


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Logo = styled.img`
  width: 200px;
  margin: 10px 0 0 0;
  padding-left: 10px;
  @media only screen and (min-width: 1100px) {
    margin: 20px 0 0 0;
    padding-left: 15px;
    width: 300px;
  }
`

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50em;
  margin-top: 0;
  @media only screen and (min-width: 1100px) {
    margin-top: 1em;
  }
`

const Me = styled.img`
  width: 200px;
  margin: 10px;
  border-radius: 50%;
`

const Paragraph = styled.p`
  font-size: ${FONTS.size.small}px;
  line-height: 1.5;
  max-width: 50em;
  margin: 30px auto;
  padding: 0 10px;
  @media only screen and (min-width: 700px) {
    font-size: ${FONTS.size.base}px;
  }
`

const Intro = styled(Paragraph)`
  margin: 0;
  text-align: center;
  margin: 15px auto;
  @media only screen and (min-width: 1100px) {
    margin: 30px auto 0;
  }
`

const QuotesContainer = styled.div`
  background: rgba(0,0,0,0);
  width: 100%;
  margin-bottom: 50;
  @media only screen and (min-width: 700px) {
    marginBottom: 100;
  }
`

const IndexPage = () => {
  const { people, weddings, dance } = useStaticQuery(pageQuery)
  return (
    <Container>
      <Helmet
        title="Ben Hejkal Photography"
      // meta={[
      // { name: 'description', content: 'Sample' },
      // { name: 'keywords', content: 'sample, something' },
      // ]}
      />
      <Logo src={logo} />

      <IntroContainer>
        <Me src={me} />
        <Intro>
          Hi, I'm a photographer based in Minneapolis. Easy-going and unobtrusive, I love capturing candid moments full of movement and expression. Get in touch at ben.hejkal@gmail.com.
        </Intro>
      </IntroContainer>

      <Gallery photos={people} name='people' ></Gallery>
      <QuotesContainer>
        <Paragraph>“What's great about Ben is his down-to-earth and approachable attitude. I usually dislike being in front of the camera but when Ben is the one taking the photos, it somehow just doesn't feel as intrusive. He has a way of bringing out the best in you for the shot without making you feel in the spotlight.” -Evgenia</Paragraph>
        <Paragraph>“He’s able to capture the subtleties in his photos that make images look and feel alive.” -Jamin</Paragraph>
        <Paragraph>“He has an excellent eye for moments and is astoundingly unobtrusive, leading to natural, perfectly-timed candids.” -Mike</Paragraph>
        <Paragraph>“The photo-session is like hanging out with a friend.” -Bala</Paragraph>
      </QuotesContainer>

      <Gallery photos={weddings} name='weddings' ></Gallery>
      <QuotesContainer>
        <Paragraph>
          “Ben Hejkal is the perfect event photographer. He is polite, professional, and easy to work with- before, during, and after the event. When he sent us our wedding photos, I expected to go through and pick out the good ones, but every one was good. He captured everything from the overall party to the tiniest detail; from the lace on the dress to the joyous dancing at the reception, to beautifully posed family portraits. He has an excellent eye for moments and is astoundingly unobtrusive, leading to natural, perfectly-timed candids. Our photos are far more beautiful than we could have imagined; they have a fresh natural quality that makes them timeless, and the light is perfect in every shot. There is nothing that we would change about our experience with Ben, and we will absolutely continue to use him in the future.”  -Mike
        </Paragraph>
        <Paragraph>
          “One thing that I really appreciate about Ben Hejkal is the unobtrusive quality of his presence at our wedding. He took great care to capture the all the nuances of feeling and atmosphere at our event without ever disturbing our special moments. That’s a quality of a true professional.” “Having you come photograph the day was in my opinion our single best wedding decision.”  -Andrea
        </Paragraph>
        <Paragraph>
          “We absolutely love our wedding pictures! His pictures were vivid and touching, and got great quiet moments and fun action shots of all of our friends and family. The most amazing thing about Ben, which so few wedding photographers are good at, is that he did it all while being unobtrusive to the point of being almost invisible. He captured beautiful natural pictures without having to interrupt the party, or block anyone's view. Thank you Ben! Your pictures beautifully captured the intimacy, fun and emotions that help us relive our special day!” -Maureen and Sam
        </Paragraph>
      </QuotesContainer>

      <Gallery photos={dance} name='dance' ></Gallery>
      <QuotesContainer>
        <Paragraph>
          “Ben is a photographer of life; his ability to capture both the fever-pitch moments and quieter, ephemeral details that often slip unnoticed past the public eye is a distinguishing feature of his art.  His minimalist approach to photography is advantageous in a variety of situations, but is especially appreciated in the social dance world.  The ability to turn out sharp, beautifully balanced photographs while working with a modestly-sized lens and no flash in a dimly lit room full of perpetually moving bodies is a brand of magic to which few have access; but that is Ben’s gift.  This unobtrusive style combined with his impeccable timing and discreet, often nearly invisible presence make him a trustworthy choice to have behind the lens, both on the street and in the studio (dance or otherwise!).”  -Amanda
        </Paragraph>
        <Paragraph>
          “Ben was the best photographer we've ever hired!  He was unobtrusive, but everywhere at the same time.  He captured images of things we didn't even know were going on and got to re-live the event through his photos as if we were attending it for the first time.  His timing and composition made us extremely glad he was able to fit us into his schedule, and the photos that we got out of the evening were exactly what we needed.”  -Kevin and Michelle
        </Paragraph>
        <Paragraph>
          “Ben's work is at once modern, clean, and elegant. The end result goes beyond simply recording what happened, and instead seems to capture, through some magic not entirely clear to me,  the version of things as they happened from inside your own mind.”  -David
        </Paragraph>
        <Paragraph>
          “Ben is an incredibly talented photographer and my first choice when booking a photographer.  As a professional musician and dancer it's important to me to get awesome publicity photos.  I chose Ben to shoot both my dance and music photos because of his incredible ability to beautifully catch the greatest moments of movement.  He's a joy to work with.  He's very laid back, fast, decisive, and each photo he produces is great!”  -Shawn
        </Paragraph>
      </QuotesContainer>

      <Paragraph>All photos copyright Ben Hejkal</Paragraph>
    </Container>
  )
}

export default IndexPage

const pageQuery = graphql`
  query IndexQuery {
    people: allFile(filter: {
      sourceInstanceName: { eq: "people" }
    }) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 800
              quality: 85
              traceSVG: { background: "#e6e6e6", color: "whitesmoke" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              originalName
            }
          }
        }
      }
    }
    weddings: allFile(filter: {
      sourceInstanceName: { eq: "weddings" }
    }) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 800
              quality: 85
              traceSVG: { background: "#e6e6e6", color: "whitesmoke" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              originalName
            }
          }
        }
      }
    }
    dance: allFile(filter: {
      sourceInstanceName: { eq: "dance" }
    }) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 800
              quality: 85
              traceSVG: { background: "#e6e6e6", color: "whitesmoke" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              originalName
            }
          }
        }
      }
    }
  }
`

if (typeof window !== 'undefined') {
  const next = () => {
    let index = Number(window.location.hash.split('-')[1])
    let base = window.location.hash.split('-')[0]
    window.location.hash = base + '-' + (index + 1)
  }

  const previous = () => {
    let index = Number(window.location.hash.split('-')[1])
    let base = window.location.hash.split('-')[0]
    window.location.hash = base + '-' + (index - 1)
  }

  document.addEventListener('keyup', (event) => {
    // esc closes lightbox
    if (event.which === 27) {
      window.location.hash = '#_'
    }
    // right arrow goes to next image in collection
    if (event.which === 39 && window.location.hash && window.location.hash !== '#_') {
      next()
    }
    // left arrow goes to previous image in collection
    if (event.which === 37 && window.location.hash && window.location.hash !== '#_') {
      previous()
    }
  })

  window.onhashchange = (event) => {
    if (window.location.hash === '#_') {
      window.history.replaceState("", document.title, window.location.pathname + window.location.search);
    }
  }
}
