const FONTS = {
  family: ['Montserrat', 'Arial', 'sans-serif'],
  size: {
    xsmall: 14,
    small: 16,
    base: 19,
    large: 22,
    xlarge: 40,
  },
}

export { FONTS }
