import React from 'react'
import styled from '@emotion/styled'
import GatsbyImage from 'gatsby-image'
import { logo } from '../assets/images/logo'

const Container = styled.div`
  width: 100%;
  margin: 30px 0 0;
  @media only screen and (min-width: 1100px) {
    margin: 70px 0 0;
  }
`

const LogoSmall = styled.img`
  width: 100px;
  margin: 15px 10px 10px 10px;
  z-index: 5;
`

// const CloseButton = styled.img({
//   position: 'fixed',
//   height: 35,
//   width: 35,
//   top: 15,
//   right: 15,
//   zIndex: 5,
//   opacity: 0.2
// })

const PhotoGrid = styled.div`
  column-count: 2;
  column-gap: 0;
  width: calc(100% - 10px);
  margin: 5px;
  > div {
    break-inside: avoid;
    padding: 5px;
    transform-style: preserve-3d;
    perspective: 1000;
    transition: transform .25s ease 0s;
    &:hover {
      transform: scale(0.98);
    }
  }
  @media only screen and (min-width: 700px) {
    column-count: 3;
  }
  @media only screen and (min-width: 1100px) {
    column-count: 4;
  }
`

const BigImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`

const LightBox = styled.a`
  display: none;
  &:target {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    outline: none;
    &:before {
      content: "";
      background: white;
      position: fixed;
      top: 0; left: 0; right: 0; bottom: 0;
    }
    > .gatsby-image-wrapper {
      z-index: 15 !important;
      width: 100%;
      height: calc(100% - 85px);
    }
    img {
     object-fit: contain !important;
    }
  }
`

export function Gallery({ photos, name }) {
  let counter1 = 1
  let counter2 = 1

  return (
    <Container>
      <PhotoGrid>
        {
          photos.edges.map((image) => {
            let key = name + '-' + counter1
            counter1++
            return (
              <div key={image.node.childImageSharp.fluid.originalName}>
                <a href={'#' + key}>
                  <GatsbyImage fluid={image.node.childImageSharp.fluid} />
                </a>
              </div>
            )
          })}
      </PhotoGrid>
      {
        photos.edges.map((image) => {
          let key = name + '-' + counter2
          counter2++
          return (
            <LightBox href='#_' id={key} key={image.node.childImageSharp.fluid.originalName}>
              {/* <CloseButton src={close} ></CloseButton> */}
              <LogoSmall src={logo} ></LogoSmall>
              <BigImage fluid={image.node.childImageSharp.fluid} outerWrapperClassName='big-image' />
            </LightBox>
          )
        })}
    </Container>
  )
}
